const initNatureSlider = () => {
  const slider = new Swiper('[data-nature-slider]', {
    slidesPerView: 1,
    spaceBetween: 35,
    loop: true,
    pagination: {
      el: '[data-nature-pagination]',
      clickable: true,
      bulletClass: 'slider-pagination__button',
      bulletActiveClass: 'is-active',
    },
  });
};

export {initNatureSlider};

