import {ScrollLock} from '../utils/scroll-lock';

const mainNav = document.querySelector('[data-main-nav]');

const initMainNavMenu = () => {
  if (!mainNav) {
    return;
  }

  const mainNavToggle = mainNav.querySelector('[data-main-nav-toggle]');
  const mainNavLinks = mainNav.querySelectorAll('[data-main-nav-link]');
  const mainNavOverlays = document.querySelectorAll('[data-main-nav-overlay]');

  const scrollLock = new ScrollLock();

  const openMenu = () => {
    mainNav.classList.add('is-active');
    mainNavToggle.classList.add('is-active');
    mainNavToggle.classList.add('is-not-click');

    setTimeout(()=> {
      mainNavToggle.classList.remove('is-not-click');
    }, 400);

    scrollLock.disableScrolling();
  };

  const closeMenu = () => {
    mainNav.classList.remove('is-active');
    mainNavToggle.classList.remove('is-active');
    scrollLock.enableScrolling();
  };

  const onEscPress = (evt) => {
    const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

    if (isEscKey && mainNav.classList.contains('is-active')) {
      evt.preventDefault();
      closeMenu();
    }
  };

  const menuHandler = () => {
    if (mainNav.classList.contains('is-active')) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  mainNavLinks.forEach((menuLink) => {
    menuLink.addEventListener('click', function () {
      setTimeout(closeMenu, 600);
    });
  });

  mainNavToggle.addEventListener('click', menuHandler);
  mainNavOverlays.forEach((item) => {
    item.addEventListener('click', menuHandler);
  });

  window.addEventListener('keydown', onEscPress);
};

export {initMainNavMenu};
