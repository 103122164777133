const sliderChangeHandler = () => {
  const peopleSection = document.querySelector('[data-people="section"]');

  if (!peopleSection) {
    return;
  }

  const peopleSectionVids = peopleSection.querySelectorAll('[data-video="video"]');
  const peopleSectionVidContainers = peopleSection.querySelectorAll('[data-video="video-container"]');
  const videoBtns = peopleSection.querySelectorAll('[data-video="play-btn"]');

  if (peopleSectionVids) {
    peopleSectionVids.forEach((video) => {
      if (video.querySelector('source').getAttribute('src') !== '#') {
        video.pause();
        setTimeout(() => {
          video.querySelector('source').src = '#';
          video.load();
        }, 600);
      }
    });

    peopleSectionVidContainers.forEach((wrapper) => wrapper.classList.remove('is-active'));
    videoBtns.forEach((btn) => btn.classList.remove('is-hidden'));

    setTimeout(() => {
      initActiveSlidePlayBtn();
    }, 600);

  }
};

const initActiveSlidePlayBtn = () => {
  const peopleSection = document.querySelector('[data-people="section"]');

  if (!peopleSection) {
    return;
  }

  const videoBtns = peopleSection.querySelectorAll('[data-video="play-btn"]');
  videoBtns.forEach((btn) => btn.setAttribute('tabindex', -1));

  const activeVidBlock = peopleSection.querySelector('.swiper-slide-active');
  const playBtn = activeVidBlock.querySelector('[data-video="play-btn"]');
  playBtn.setAttribute('tabindex', 0);
};

const videoOnEndHandler = (e) => {
  const video = e.target;
  const videoBlock = video.closest('[data-video="block"]');
  const videoPlayBtn = videoBlock.querySelector('[data-video="play-btn"]');

  videoPlayBtn.classList.remove('is-hidden');
};

const peopleVidClickHandler = (e) => {
  const target = e.target;

  if (!target.closest('[data-people="section"]')) {
    return;
  }

  e.preventDefault();


  if (target.closest('[data-video="play-btn"]')) {
    const playBtn = target.closest('[data-video="play-btn"]');
    const videoSrc = playBtn.dataset.videoSrc;
    const video = target.closest('[data-video="block"]').querySelector('[data-video="video"]');
    const videoContainer = video.closest('[data-video="video-container"]');


    if (video.querySelector('source').getAttribute('src') === '#') {
      video.querySelector('source').src = `${videoSrc}`;
      video.load();
      videoContainer.classList.add('is-active');

      video.addEventListener('ended', videoOnEndHandler);
    }

    if (video.ended) {
      video.currentTime = 0;
    }

    video.play();
    playBtn.classList.add('is-hidden');
  }

  if (target.closest('[data-video="video-container"]')) {
    const video = target.closest('[data-video="block"]').querySelector('[data-video="video"]');
    const playBtn = target.closest('[data-video="block"]').querySelector('[data-video="play-btn"]');

    if (video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2) {
      video.pause();
      playBtn.classList.remove('is-hidden');
    }
  }
};

const initPeopleVideo = () => {
  const peopleSection = document.querySelector('[data-people="section"]');

  if (!peopleSection) {
    return;
  }

  if (!peopleSection.querySelector('[data-video="video"]')) {
    return;
  }

  document.addEventListener('click', peopleVidClickHandler);
};

export {initPeopleVideo, sliderChangeHandler, initActiveSlidePlayBtn};
