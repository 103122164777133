import MoveTo from '../vendor/move-to';
import {getTolerance} from './init-move-to';

const initPagePositionCheck = () => {

  const elementId = window.location.hash.slice(1);

  if (elementId.length === 0) {
    return;
  }

  const foundElement = document.getElementById(elementId);

  const moveToElement = new MoveTo({
    tolerance: getTolerance(),
    duration: 600,
    easing: 'easeOutQuart',
    container: window,
  });

  setTimeout(() => {
    moveToElement.move(foundElement);
  }, 300);

};

export {initPagePositionCheck};
