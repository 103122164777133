import {resetWindowPosition} from './utils/reset-window-position';
import {iosVhFix} from './utils/ios-vh-fix';
import {initModals} from './modules/modals/init-modals';
import {initCustomSelect} from './modules/form/init-custom-select';
import {initFormValidate} from './modules/form/init-form-validate';
import {initMainNavMenu} from './modules/init-main-nav-menu';
import {initLocationSlider} from './modules/init-location-slider';
import {initNatureSlider} from './modules/init-nature-slider';
import {initMoveTo} from './modules/init-move-to';
// import {initMainScreenSlider} from './modules/init-main-screen-slider';  Расскомментировать для запуска слайдера на главном экране
import {initAccordions} from './modules/accordions/init-accordion';
import {initTabs} from './modules/tabs/init-tabs';
import {initPeopleVidSlider} from './modules/init-people-video-slider';
import {initPeopleVideo} from './modules/init-people-video';
import {initPagePositionCheck} from './modules/init-page-position-check';
import {initVkMusic} from './modules/init-vk-music';
import {scrollToTop} from './utils/scroll-to-top';
import {openMap} from './utils/open-map';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  scrollToTop();
  resetWindowPosition();
  iosVhFix();
  initMainNavMenu();
  initMoveTo();
  // initMainScreenSlider(); Расскомментировать для запуска слайдера на главном экране
  // Modules
  // ---------------------------------

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    openMap();
    initModals();
    initCustomSelect();
    initFormValidate();
    initAccordions();
    initTabs();
    initLocationSlider();
    initNatureSlider();
    initPeopleVidSlider();
    initPeopleVideo();
    initPagePositionCheck();
    initVkMusic();
  });
});

// ---------------------------------

// ❗❗❗ обязательно установите плагины eslint, stylelint, editorconfig в редактор кода.

// привязывайте js не на классы, а на дата атрибуты (data-validate)

// вместо модификаторов .block--active используем утилитарные классы
// .is-active || .is-open || .is-invalid и прочие (обязателен нейминг в два слова)
// .select.select--opened ❌ ---> [data-select].is-open ✅

// выносим все в дата атрибуты
// url до иконок пинов карты, настройки автопрокрутки слайдера, url к json и т.д.

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// используйте .closest(el)
