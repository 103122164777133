import {initActiveSlidePlayBtn, sliderChangeHandler} from './init-people-video';

const peopleSection = document.querySelector('[data-people="section"]');
const peopleVidSlider = document.querySelector('[data-people="slider"]');


const initPeopleVidSlider = () => {
  if (!peopleVidSlider) {
    return;
  }

  const pagination = peopleSection.querySelector('[data-people="slider-pagination"]');

  const vidSlider = new Swiper (peopleVidSlider, {

    slidesPerView: 1,
    spaceBetween: 35,
    speed: 600,
    allowTouchMove: true,
    watchOverflow: true,
    pagination: {
      el: pagination,
      clickable: true,
      bulletClass: 'slider-pagination__button',
      bulletActiveClass: 'is-active',
    },
    navigation: {
      nextEl: '.people__slider-button--next',
      prevEl: '.people__slider-button--prev',
    },
    a11y: {
      prevSlideMessage: 'Предыдущий слайд',
      nextSlideMessage: 'Следующий слайд',
    },
  });

  vidSlider.on('afterInit', initActiveSlidePlayBtn());
  vidSlider.on('slideChange', () => sliderChangeHandler());
};

export {initPeopleVidSlider};
