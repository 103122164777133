const locationSliders = document.querySelectorAll('[data-slider-location]');
// const breakpointL = window.matchMedia('(max-width:1279px)');
// const breakpointMD = window.matchMedia('(max-width:1023px)');
// const breakpointSM = window.matchMedia('(max-width:767px)');

let swiper;
let pagination;
let slides;

const getSlides = () => {
  if (slides.length > 3) {
    return 3;
  } else {
    return slides.length;
  }
};

const initSwiper = (slider) => {
  pagination = slider.closest('[data-slider-location-container]').querySelector('[data-slider-pagination]');
  slides = slider.querySelectorAll('[data-slider-slide]');

  swiper = new Swiper(slider, {
    speed: 600,
    allowTouchMove: true,
    watchOverflow: true,
    pagination: {
      el: pagination,
      clickable: true,
      bulletClass: 'slider-pagination__button',
      bulletActiveClass: 'is-active',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      540: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      1024: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 32,
      },
      1280: {
        slidesPerView: getSlides(),
        slidesPerGroup: 1,
        spaceBetween: 32,
      },
    },
  });
};

const initLocationSlider = () => {
  if (!locationSliders.length) {
    return;
  }

  for (let i = 0; i < locationSliders.length; i++) {
    initSwiper(locationSliders[i]);
  }
};

export {initLocationSlider};
