import MoveTo from '../vendor/move-to';
const breakpointSM = window.matchMedia('(max-width:767px)');

const getTolerance = () => {
  let height;
  if (breakpointSM.matches) {
    height = 30;
  } else {
    height = 44;
  }

  return height;
};

const initMoveTo = () => {

  const moveTo = new MoveTo({
    tolerance: getTolerance(),
    duration: 600,
    easing: 'easeOutQuart',
    container: window,
  });

  const triggersWrappers = document.querySelectorAll('[data-link-trigger-container]');
  const triggers = document.querySelectorAll('[data-link-trigger]');

  if (!triggers.length) {
    return;
  }

  if (!triggersWrappers.length) {
    return;
  }

  for (let i = 0; i < triggersWrappers.length; i++) {
    const activeTriggers = triggersWrappers[i].querySelectorAll('[data-link-trigger]');

    activeTriggers.forEach((trigger) => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault();
        const triggerHref = trigger.getAttribute('href');
        if (triggerHref.indexOf('#') !== 0) {
          window.location = triggerHref;
        } else {
          const element = document.getElementById(triggerHref.slice(1));
          moveTo.move(element);
        }

        let array = Array.from(activeTriggers);
        let index = array.indexOf(trigger);
        array.forEach((item, j) => {
          if (j === index) {
            item.classList.add('is-active');
          } else {
            item.classList.remove('is-active');
          }
        });
      });
    });
  }
};

export {initMoveTo, getTolerance};
