const initVkMusic = () => {
  const vkMusicSurgut = document.querySelector('[data-vk-music-surgut]');

  if(!vkMusicSurgut) {
    return;
  }

  (function () {
    VK.Widgets.Playlist("vk_playlist_-163590773_1", -163590773, 1,'f48599a5252f8d7fc9');
  }());
};

export {initVkMusic};
