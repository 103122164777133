const routes = [
  {id: 'route1', src: 'https://yandex.ru/map-widget/v1/-/CCUNnLgNxD'},
  {id: 'route2', src: 'https://yandex.ru/map-widget/v1/-/CCUNnLwU~D'},
  {id: 'route3', src: 'https://yandex.ru/map-widget/v1/-/CCURV-Ec9D'},
  {id: 'route4', src: 'https://yandex.ru/map-widget/v1/-/CCURV-Q1wB'},
  {id: 'route5', src: 'https://yandex.ru/map-widget/v1/-/CCURV-aqkC'},
  {id: 'route6', src: 'https://yandex.ru/map-widget/v1/-/CCURV-e0dD'},
  {id: 'route7', src: 'https://yandex.ru/map-widget/v1/-/CCURV-u4tD'}
];

const route = document.querySelector('[data-map="route"]');
const buttons = document.querySelectorAll('[data-button="map"]');
const text = document.querySelector('[data-location-map="text"]');

const handleClick = (id) => {
  const currentRoute = routes.find((item) => item.id === id);
  route.setAttribute('src', currentRoute.src);
  text.hidden = true;
};

const openMap = () => {
  for (let button of buttons) {
    button.addEventListener('click', () => handleClick(button.id));
  }
};

export {openMap};
